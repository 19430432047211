<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
        <md-content style="width: 1200px;">
            <div class="page-content" style="height: 95vh; overflow: auto; width: 100%;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-shortkey="['ctrl', 'i']" @shortkey="close()" @click="close()" class="md-raised">Đóng</md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body" style="margin-bottom: 20px;">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px;">
                                        <div class="group-title">THÔNG TIN CHUNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.payslipCode.$error }">
                                                        <label for="payslipCode">Số phiếu</label>
                                                        <md-input name="payslipCode" disabled v-model="entity.payslipCode"></md-input>
                                                        <span class="md-error" v-if="submitted && !$v.entity.payslipCode.required">Vui lòng nhập số phiếu</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.payslipDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.payslipDate.$error }">
                                                        <label>Ngày lập</label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.payslipDate.required">Vui lòng chọn ngày tạo</span>
                                                    </md-datepicker>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                                            <label>Nhân viên phụ trách</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openStaff()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers" :class="{'md-invalid': submitted && $v.entity.driverId.$error }">
                                                            <label>Lái xe</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.driverId.isSelected">Vui lòng chọn lái xe</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openDriver()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm lái xe</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div> 
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-field :class="{'md-invalid': submitted && $v.entity.year.$error }">
                                                            <label for="year">Năm</label>
                                                            <md-input name="year" v-model="entity.year"></md-input>
                                                            <span class="md-error" v-if="submitted && !$v.entity.year.required">Vui lòng nhập năm tính lương</span>
                                                        </md-field>
                                                        <md-field>
                                                            <label>Tháng</label>
                                                            <md-select v-model="entity.month">
                                                                <md-option v-for="item in monthOfYear" :key="'month-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                            </md-select>
                                                        </md-field>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-amount">
                                                        <div class="form-amount-item">
                                                            <label for="note">Tổng lương</label>
                                                            <span>{{formatNumber(entity.totalSalary)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Lương chuyến</label>
                                                            <span>{{formatNumber(entity.commission)}}</span>
                                                        </div>
                                                        <div class="form-amount-item">
                                                            <label for="note">Lương cố định</label>
                                                            <span>{{formatNumber(entity.baseSalary)}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <md-field>
                                                        <label for="note">Ghi chú</label>
                                                        <md-input name="note" v-model="entity.note" ></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-tab">
                                <md-tabs>
                                    <md-tab id="tab-detail" class="tab-content" md-label="Chi tiết phiếu lương">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar"></div>
                                                    <table class="data-table-2" style="width: 100%;"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th class="left" style="width:21%;">Mã điều xe</th>
                                                                <th class="center" style="width:15%;">Ngày thực hiện</th>
                                                                <th class="left" style="width:20%;">Tên phí</th>
                                                                <th class="left" style="width:12%;">Loại phí</th>
                                                                <th class="right" style="width:12%;">Số tiền</th>
                                                                <th class="right" style="width:15%;">Ghi chú</th>
                                                            </tr>
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false && entity.details.length == 0" style="height: 100px;"> 
                                                            <tr><td colspan="100" class="no-data">Không có lương chuyến</td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false && entity.details.length > 0"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="left">
                                                                    {{ item.job.jobCode }}
                                                                </td>
                                                                <td class="center">
                                                                    {{ item.job.submitDate }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ item.fee.feeName }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ getFeeType(item.type) }}
                                                                </td>
                                                                <td class="right">
                                                                    {{ formatNumber(item.amount) }}
                                                                </td>
                                                                <td class="left">
                                                                    {{ item.note }}
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:20%;">Bộ phận/phòng</th> 
                                                                <th style="width:20%;">Người phê duyệt</th> 
                                                                <th style="width:17%;">Tiêu đề</th>
                                                                <th style="width:18%;">Ghi chú</th> 
                                                                <th style="width:10%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.dept.name" class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.staff.fullName" class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.note" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="text" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeApprove"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
                <driverList ref="driverList" @close="closeDriver"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import payslipService from '@/api/payslipService';
    import approveSettingService from '@/api/approveSettingService';
    import staffService from '@/api/staffService';
    import driverService from '../../../api/driverService';
    import messageBox from '@/utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '@/mixins';
    import staffList from '@/components/popup/_StaffList.vue';
    import deptList from '@/components/popup/_DepartmentList.vue';
    import driverList from '../../../components/popup/_DriverList.vue';
    import approveList from '@/components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            driverList,
            approveList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật bảng lương lái xe'
        },
        data() {
            return {
                title: '',
                id: 0,
                tab: 0,
                showDialog: false,
                submitted: false,
                loadding: false,
                entity: { id: 0, driverId: 0, year: common.yearNow, month: common.monthNow, details: [], approveList: [], staffId: 0, staff: {id: 0, fullName: ''}, payslipCode: '', payslipDate: common.dateNow, commission: 0, baseSalary: 0, totalSalary: 0, note: '' },
                approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, isApproved: false, ApprovedAt: null },
                staffName: '',
                staffs: [],
                driverName: '',
                drivers: [],
                monthOfYear: common.monthOfYear,
            }
        },
        created(){
            
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            close(){
                this.showDialog = false;
            },

            getDriverSalary(){
                this.setLoading(true);
                let month = this.entity.month.toString();
                if(month.length == 1){
                    month = "0" + this.entity.month;
                }
                const fromDate = '01-' + month + "-" + this.entity.year;
                const toDate = '31-' + month + "-" + this.entity.year;
                const search = { driverId: this.entity.driverId, fromDate: fromDate, toDate:  toDate};
                payslipService.getDriverSalary(search).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.details = [];
                        response.data.map((item) => {
                            let obj = {
                                payslipId: 0,
                                jobId: item.jobId,
                                job: item.job,
                                estimateId: item.estimateId,
                                estimateDetailId: item.estimateDetailId,
                                feeId: item.feeId,
                                fee: item.fee,
                                type: item.type,
                                price: item.price,
                                vat: item.vat,
                                amount: item.amount,
                                note: item.note
                            };
                            this.entity.details.push(obj);
                        });
                        this.calTotal();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            async open(){
                this.title = "Thêm mới bảng lương lái xe";
                this.getByAccount();
                this.entity.approveList = [];
                this.entity.approveList.push(this.approve);
                this.showDialog = true;
            },

            async openEdit(id){
                this.title = "Cập nhật bảng lương lái xe";
                this.id = id;
                this.getById();
                this.showDialog = true;
            },
            
            //Driver
            closeDriver(item){
                this.driverName = item.fullName;
                this.entity.driverId = item.id;
                this.entity.baseSalary =  item.salaryBase;
                this.entity.totalSalary =  this.entity.commission + this.entity.baseSalary;
                this.getDriverSalary();
                this.$refs.driverList.close();
            },

            getDriverSelected(item){
                console.log(item)
                this.entity.driverId = item.id;
                this.driverName = item.fullName;
                this.entity.baseSalary =  item.salaryBase;
                this.entity.totalSalary =  this.entity.commission + this.entity.baseSalary;
                this.getDriverSalary();
            },

            openDriver(){
                this.$refs.driverList.open();
            },

            getDrivers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
                driverService.getDrivers(search).then((response) => {
                    if(response.statusCode == 200){
                        this.drivers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            calTotal(){
                this.entity.commission = 0;
                this.entity.totalSalary = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    let item = this.entity.details[i];
                    this.entity.commission = this.entity.commission + parseFloat(item.amount);
                }
                this.entity.totalSalary =  this.entity.commission + this.entity.baseSalary;
            },

            uuidv4(id) {
                let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
                );

                return uuid + "-" + id;
            },

            //DEPT
            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            getCode(staffId){
                this.setLoading(true);
                payslipService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.payslipCode = response.data.code;
                        this.entity.invoiceNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.customsjob).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairjob), orderSort: 1, status: 1, ApprovedAt: null };
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },
            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId.id == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("Vui lòng nhập chi tiết lương");
                    return;
                }
                if(this.entity.approveList.length <= 0){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                payslipService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                payslipService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.entity.branchId = response.data.department.branchId;
                            this.staffName = response.data.fullName;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                payslipService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.driverName = response.data.driver.fullName;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.staffName = item.fullName;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept.code = item.department.code;
                this.entity.approveList[0].dept.name = item.department.name;
                this.entity.approveList[0].staff.code = item.code;
                this.entity.approveList[0].staff.fullName = item.fullName;
                this.entity.approveList[0].title = "Người đề nghị";
                this.getCode(item.id);
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            back(){
                this.$router.push('/job-invoice?orderType=' + this.orderType);
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            driverName: function (val) { 
                if(val == ''){
                    this.entity.driverId = 0;
                    this.entity.details = [];
                    this.entity.amount = 0;
                }
            },
        },
        validations: {
            entity: {
                driverId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                payslipCode: { required },
                payslipDate: { required },
                year: { required },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                }
            }
        }
    })
</script>


<style scoped>
    .btn-add {
        background-color: #007bff;
        padding: 7px 10px;
        color: #FFF !important;
        border-radius: 3px;
    }
    .btn-add i {
        color: #FFF !important;
    }
    .job-list {
        border: 1px solid #ccc;
    }
    .box-item:first-child {
        padding-top: 5px;
    }
    .box-item {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .box-item > label {
        width: 130px;
    }
</style>