import httpClient  from '.'; 
const API_CONTROLLER = 'payslip/'; 


export default { 
    getCode(staffId) { 
        let url = API_CONTROLLER + 'get-code?staffId=' + staffId;
        return httpClient.get(url);
    },
    
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getByJobId(jobId) { 
        let url = API_CONTROLLER + 'get-by-job-id';
        return httpClient.get(url, {
            params: {
                jobId: jobId
            }
        });
    },

    getDriverSalary(search) { 
        let url = API_CONTROLLER + 'get-driver-salary';
        return httpClient.get(url, {
            params: {
                driverId: search.driverId,
                fromDate: search.fromDate,
                toDate: search.toDate
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                status: search.status,
                driverId: search.driverId,
                year: search.year,
                month: search.month
            }
        });
    },

    getForApproves(search) { 
        let url = API_CONTROLLER + 'get-for-approves';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                status: search.status,
                staffStatus: search.staffStatus,
            }
        });
    },

    getForApprove(id) { 
        let url = API_CONTROLLER + 'get-for-approve';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    submitApprove(data) { 
        let url = API_CONTROLLER + 'submit-approved';
        return httpClient.post(url, data);
    },
}