import httpClient  from '../api'; 
const API_CONTROLLER = 'driver/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    addAccount(data) { 
        let url = API_CONTROLLER + 'add-account';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getAccount(id) { 
        let url = API_CONTROLLER + 'get-account';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                name: search.name
            }
        });
    },

    getDrivers(search) { 
        let url = API_CONTROLLER + 'get-drivers';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                name: search.name
            }
        });
    },

    getAssistantDrivers(search) { 
        let url = API_CONTROLLER + 'get-assistant-driver';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                code: search.code,
                name: search.name
            }
        });
    },


    getByAccount() { 
        let url = API_CONTROLLER + 'get-by-account';
        return httpClient.get(url);
    },
}